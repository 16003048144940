<template>
  <div>
    <div class="mb-4">
      <span
        v-if="dataConds"
        class="mr-2"
      >{{dataConds.length}}</span>
      <span>{{$t('t.Conditions')}}</span>
      <v-btn
        fab
        x-small
        color="primary"
        @click="addCondition"
        class="ml-4"
        v-if="!readonly"
      >
        <v-icon>{{$icon('i.Plus')}}</v-icon>
      </v-btn>
    </div>
    <grid
      item-min-width="450px"
      class="align-start"
    >
      <v-card
        v-for="(c, idx) in dataConds"
        :key="idx + '_0'"
        class="ma-0 cond"
      >
        <v-card-text>
          <div class="d-flex">
            <div class="d-flex flex-column">
              <v-badge
                offset-y=17
                offset-x=8
                color="error"
                :value="!conditionIdUsed(idx)"
              >
                <template v-slot:badge>
                  <icon-tooltip
                    :icon-name="'i.ExclamationThick'"
                    :tooltip-text="$t('t.ConditionNoUsedInExpression')"
                  />
                </template>
                <v-btn
                  fab
                  x-small
                  color="primary"
                  @click="addExpToken(numberToConditionId(idx))"
                >
                  <span class="conditionId">{{numberToConditionId(idx)}}</span>
                </v-btn>
              </v-badge>
              <v-btn
                class="mt-2"
                fab
                x-small
                @click="removeCondition(idx)"
                v-if="dataConds.length > 1 && !readonly"
              >
                <v-icon>{{$icon('i.Delete')}}</v-icon>
              </v-btn>
            </div>
            <cond
              class="ml-4"
              :document="dataConds[idx]"
              :target-doc-ty="targetDocTy"
              @update:document="setCond(idx, $event)"
              :audit-result="computedAuditConds ? computedAuditConds[idx] : null"
              :readonly="readonly"
            />
          </div>

        </v-card-text>
      </v-card>
    </grid>
    <v-card class="ma-0 mt-4 expr">
      <v-card-title>
        <audit-bool-icon
          class="mr-2"
          :value="computedAuditExpr"
          v-if="auditResult"
        />
        <span>{{$t('t.Expressions')}}</span>
      </v-card-title>
      <v-card-text class="pt-0 mt-0 mb-4">
        <div>
          <div class="d-flex flex-row flex-wrap expression-btns overflow-visible">
            <div
              class="mr-4 "
              v-for="(c, idx) in dataConds"
              :key="idx + '_1'"
            >
              <v-badge
                offset-y=17
                offset-x=8
                color="error"
                :value="!conditionIdUsed(idx)"
              >
                <template v-slot:badge>
                  <icon-tooltip
                    :icon-name="'i.ExclamationThick'"
                    :tooltip-text="$t('t.ConditionNoUsedInExpression')"
                  />
                </template>
                <v-btn
                  fab
                  x-small
                  color="primary"
                  @click="addExpToken(numberToConditionId(idx))"
                >
                  <span class="conditionId">{{numberToConditionId(idx)}}</span>
                </v-btn>
              </v-badge>
            </div>
          </div>
          <v-text-field
            v-model="computedExpr"
            :clearable="true"
            ref="expression"
            :placeholder="expressionPlaceholder"
          />
          <div v-if="!readonly">
            <v-btn
              fab
              x-small
              color="primary"
              class="mr-2"
              v-for="(c, idx) in expTokens"
              :key="idx + '_2'"
              @click="addExpToken(c.id)"
            >{{c.text}}</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>

import auditController from '@/auditController'

export default {
  components: {
    AuditBoolIcon: () => import('@/components/audit-bool-icon'),
    Cond: () => import('./cond'),
    Grid: () => import('@/components/grid'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    expressionPlaceholder () {
      let r = ''
      for (let i = 0; i < this.dataConds.length; i++) {
        r += `${this.numberToConditionId(i)} And `
      }
      return r.substring(0, r.length - 4)
    },
    computedExpr: {
      get () { return this.dataExpr },
      set (v) {
        this.dataExpr = v
        this.validateExpression()
        this.dataDocumentEmit()
      }
    },
    currenciesCacheType () {
      return ['relative-currencies', 'currencies']
    },
    computedAuditConds () {
      return this.auditResult?.annotations?.conds ?? null
    },
    computedAuditExpr () {
      return this.auditResult?.annotations?.result
    }
  },
  data () {
    return {
      auditController: auditController,
      selectedConditions: {},
      expTokens: [
        { id: 'not', text: 'Not' },
        { id: 'and', text: 'And' },
        { id: 'or', text: 'Or' },
        { id: '(', text: '(' },
        { id: ')', text: ')' }
      ],
      dataConds: [{}],
      dataExpr: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { conds: this.dataConds, expr: this.dataExpr }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    setCond (idx, value) {
      this.dataConds[idx] = this.lodash.cloneDeep(value)
      this.dataDocumentEmit()
    },
    numberToConditionId (n) {
      let s = ''
      do {
        s = String.fromCharCode(n % 26 + 65) + s
        n = Math.floor(n / 26) - 1
      } while (n >= 0)

      return s
    },
    validateExpression () {
      let allUsed = true
      for (let i = 0; i < this.dataConds.length; i++) {
        if (!this.conditionIdUsed(i)) {
          allUsed = false
          break
        }
      }
      this.$emit('expression-is-valide', allUsed)
      return allUsed
    },
    conditionIdUsed (idx) {
      const condId = this.numberToConditionId(idx)
      if (this.computedExpr && this.computedExpr.length > 0) {
        const result = new RegExp(`\\b${condId}\\b`, 'ig').test(this.computedExpr)
        return result
      } else {
        return true
      }
    },
    addCondition () {
      this.dataConds.push({})
    },
    removeCondition (idx) {
      this.dataConds.splice(idx, 1)
      if (this.dataConds.length === 0) {
        this.dataConds = [{}]
      }
      this.dataDocumentEmit()
    },
    reset () {
      this.dataConds = [{}]
    },
    addExpToken (t) {
      if (this.readonly) {
        return
      }
      this.computedExpr = this.execInsertText(` ${t} `)
    },
    execInsertText (insertText) {
      const textField = this.$refs.expression.$refs.input

      textField.focus()
      textField.setRangeText(insertText, textField.selectionStart, textField.selectionEnd, 'end')

      return textField.value
    }
  },
  watch: {
    document: {
      handler (v) {
        const condExpr = this.lodash.cloneDeep(v)
        this.dataConds = condExpr?.conds || [{}]
        this.dataExpr = condExpr?.expr
        this.validateExpression()
      },
      immediate: true
    }
  },
  props: {
    targetDocTy: String,
    document: Object,
    auditResult: Object,
    readonly: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.conditionId
  font-size 1.35em

.cond
  height 100%
</style>
